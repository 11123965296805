<template>
  <div class="mx-2 my-2">
    <v-card
      class="d-flex align-center rounded-lg pa-4 pt-8 mb-1 mx-1"
      height="60"
      flat
    >
      <v-text-field
        hide-details
        v-model="search"
        append-icon="mdi-magnify"
        :label="$t('writeWhatYouSearch')"
        outlined
        dense
        class="mt-2"
      >
        <template v-slot:append-outer>
          <v-card-actions style="margin-top: -19px" class="pt-0">
            <v-checkbox
              id="isLiveOnlyKey"
              v-model="isLiveOnly"
              :label="$t('exam.ShowOnlyActive')"
            ></v-checkbox>

            <v-btn
              icon
              light
              :color="currentView == 'large' ? 'primary' : ''"
              @click="setView('large')"
            >
              <v-icon>mdi-grid-large</v-icon>
            </v-btn>
            <v-btn
              icon
              light
              :color="currentView == 'details' ? 'primary' : ''"
              @click="setView('details')"
            >
              <v-icon>mdi-view-list</v-icon>
            </v-btn>
          </v-card-actions>
        </template>
      </v-text-field>
    </v-card>

    <v-card class="ma-2" flat v-if="isInRole(2)">
      <v-card-title class="pa-0 ma-0 mx-2">
        <v-icon v-if="currentFolder" color="primary" class="ma-2"
          >mdi-folder-open</v-icon
        >
        <v-icon v-else color="primary" class="ma-2">mdi-home</v-icon>
        <div v-if="currentFolder">{{ currentFolder.folderName }}</div>
        <div v-else>{{ $t("lecOne.Home") }}</div>

        <v-card-actions v-if="foldersFilter && foldersFilter.length > 0">
          <v-btn depressed @click="showFolders=!showFolders">
            {{ $t("folders") }} ({{ foldersFilter.length }})
            <v-icon>
              {{
                !showFolders ? "mdi-chevron-up" : "mdi-chevron-down"
              }}</v-icon
            >
          </v-btn>
        </v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          v-if="isInRole(2)"
          tile
          color="secondary"
          to="/questionsBank"
          class="rounded ma-2"
        >
          {{ $t("exam.questionsBank") }}
          <v-icon right> mdi-frequently-asked-questions </v-icon>
        </v-btn>

        <v-btn tile color="primary" @click="back()" class="rounded-xl ma-2">
          {{ $t("back") }}
          <v-icon right> mdi-arrow-left </v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-expand-transition  v-if="foldersFilter && foldersFilter[0]">
        <div v-show="showFolders">
      <v-slide-group
        multiple
        show-arrows
        class="py-4"
        mandatory
      >
        <v-slide-item
          v-for="(item, index) in foldersFilter"
          :key="index"
          class="mx-2"
        >
          <v-card outlined width="180">
            <v-card-text class="ma-0 pa-0 pt-1">
              <v-menu v-if="isInRole(2)">
                <template v-slot:activator="{ on: menu, attrs }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip }">
                      <v-btn
                        icon
                        color="grey"
                        :class="
                          $vuetify.rtl
                            ? 'mt-n0 mx-n2 zin posRight'
                            : 'mt-n0 mx-n2 zin posLeft'
                        "
                        v-bind="attrs"
                        v-on="{ ...tooltip, ...menu }"
                      >
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("more") }}</span>
                  </v-tooltip>
                </template>
                <v-list>
                  <v-list-item @click="editFolder(item)">
                    <v-list-item-title>{{ $t("edit") }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="deleteFolder(item)">
                    <v-list-item-title>{{ $t("delete") }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="moveFolderPopUp(item)">
                    <v-list-item-title>{{ $t("Move") }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>

              <v-row>
                <v-col
                  lg="12"
                  md="12"
                  sm="12"
                  class="py-0 ma-0 mt-5"
                  align="center"
                >
                  <v-btn
                    width="75px"
                    height="75px"
                    icon
                    @click="OpenFolder(item.guid)"
                  >
                    <v-icon color="primary" size="70">mdi-folder</v-icon>
                  </v-btn>
                </v-col>
                <v-col
                  lg="12"
                  md="12"
                  sm="12"
                  class="py-0 ma-0 mb-2"
                  align="center"
                >
                  <v-list-item two-line>
                    <v-list-item-content class="py-0 ma-0 mb-5">
                      <v-list-item-title
                        dir="auto"
                        :title="item.folderName"
                        class="mb-1"
                        style="text-align: center; overflow: hidden !important"
                      >
                        {{ item.folderName }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="caption">{{
                        item.createdDate | moment("YYYY/MM/DD - h:mm a ")
                      }}</v-list-item-subtitle>
                      <v-list-item-subtitle class="caption">{{
                        item.teacherName
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-slide-item>
      </v-slide-group></div>
      </v-expand-transition>
      <v-divider></v-divider>

      <v-card-actions v-if="isInRole(2)">
        <v-checkbox
          v-if="currentView == 'large'"
          class="select-all-cards pa-0 ma-2"
          :title="$t('SelectAll')"
          :indeterminate="someNodesSelected"
          :input-value="allNodesSelected"
          @click.stop="toggleCompleteSelection"
        ></v-checkbox>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="!rowKeysSelected || rowKeysSelected.length == 0"
          :loading="sendLoad"
          outlined
          color="primary"
          class=""
          @click="getFoldersTree('files')"
        >
          {{ $t("exam.MoveExams") }}
          <v-icon class="px-2">mdi-file-move-outline</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-row
      v-resize="onResize"
      v-if="examsFilter && examsFilter[0]"
      class="ma-0"
    >
      <v-col
        cols="12"
        lg="12"
        md="12"
        sm="12"
        v-if="currentView == 'details'"
        class="pa-2"
      >
        <v-data-table
          style="margin-bottom: 60px"
          :headers="getHeaders()"
          :items="examsFilter"
          :search="search"
          :single-select="!isInRole(2)"
          :show-select="isInRole(2)"
          v-model="rowKeysSelected"
          selectable-key="guid"
          item-key="guid"
          @click:row="function (item, row) {
              if (!isInRole(2)) {
                row.select(true);
              }
            }
          "
        >
          <template v-slot:[`item.title`]="{ item }">
            <v-list-item two-line class="pa-1">
              <v-menu v-if="isInRole(2)" >
                <template v-slot:activator="{ on: menu, attrs }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ }">
                      <v-btn  class="px-2"  depressed
                        style="margin: 0px !important"
                        v-bind="attrs"
                        v-on="{  ...menu }"
                      >
                      <v-icon>mdi-dots-vertical</v-icon>
                      {{  $t("options") }}
                      </v-btn>
                    </template>
                  </v-tooltip>
                </template>
                <v-list>
                  <v-list-item @click="showExam(item)">
                    <v-list-item-icon>
                      <v-icon>mdi-pencil</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ $t("edit") }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="enterExam(item)">
                    <v-list-item-icon>
                      <v-icon>mdi-eye</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ $t('Preview')}}</v-list-item-title>
                  </v-list-item>
                  <v-divider></v-divider>

                  <v-list-item  @click="deletExam(item)">
                    <v-list-item-icon>
                      <v-icon>mdi-delete</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ $t("delete") }}</v-list-item-title>
                  </v-list-item>

                  <v-list-item @click="addDegree(item)">
                    <v-list-item-icon>
                      <v-icon>mdi-file-plus</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                      {{ $t("mail.AddAnExtraDegree") }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="sendNotiExam(item)">
                    <v-list-item-icon>
                      <v-icon>mdi-bell-ring</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{
                      $t("exam.sendNotiExam")
                    }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="reExam(item)">
                    <v-list-item-icon>
                      <v-icon>mdi-calendar-refresh</v-icon>
                    </v-list-item-icon>

                    <v-list-item-title>{{
                      $t("exam.reExam")
                    }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>

              <v-list-item-content class="pa-0 ma-0 ps-3" >
                <div class="mb-0 text-wrap">
                  {{ item.title }}
                </div>
                <v-list-item-subtitle class="pt-1">
                  {{ item.subjectName }}
                </v-list-item-subtitle>
                <v-list-item-subtitle class="caption">
                  {{ item.teacherName }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-slot:[`item.datex`]="{ item }">
            {{ item.datex | moment("YYYY/MM/DD - h:mm A ") }}
          </template>
          <template v-slot:[`item.examType`]="{ item }">
            <v-card-actions>

              <v-chip
                outlined
                v-if="item.examType == 0"
                color="teal lighten-1"
                dark
              >
                {{ $t("degrees.daily") }}
              </v-chip>
              <v-chip outlined v-else-if="item.examType == 3" color="blue" dark
                >{{ $t("degrees.Monthly") }}
              </v-chip>
              <v-chip
                outlined
                v-else-if="item.examType == 2"
                dark
                color="orange  lighten-1"
                >{{ $t("degrees.midFinal") }}
              </v-chip>
              <v-chip
                outlined
                v-else-if="item.examType == 1"
                dark
                color="red lighten-1"
                >{{ $t("degrees.final") }}
              </v-chip>
              <v-chip v-if="item.showResult"  outlined class="mx-2"  color="teal"><v-icon color="teal">mdi-check</v-icon>{{ $t('exam.showResultsStatus') }}</v-chip>

            </v-card-actions>
          </template>
          <template v-slot:[`item.startDate`]="{ item }">
            <v-card-text class="ma-0 pa-0">
              <v-chip
                outlined
                :color="!item.isLive ? 'error' : 'green'"
                class="me-2 mt-1"
                >{{ item.startDate | moment("YYYY/MM/DD - h:mm A") }}
              </v-chip>

              <v-chip
                outlined
                :color="!item.isLive ? 'error' : 'green'"
                class="mt-1"
                >{{ item.endDate | moment("YYYY/MM/DD - h:mm A") }}
              </v-chip>
            </v-card-text>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-list-item>
              <v-list-item-content class="pa-0 ma-0">
                <v-card-actions class="" >
                  <v-switch  v-if="isInRole(2)" style="width: 130px;"
                    class="ma-0"
                    hide-details
                    inset
                    @change="isActiveFun(item)"
                    :label="
                      item.isActive ? $t('exam.Active') : $t('exam.NotActive')
                    "
                    v-model="item.isActive"
                  >
                  </v-switch>


                   <!--
                  <v-switch  v-if="isInRole(2)" :disabled="item.isActive"
                    class="ma-0 mx-3"
                    hide-details
                    @change="showRes(item)"
                    color="teal"
                    v-model="item.showResult"
                    :label="$t('exam.ShowResult')"
                    inset

                  ></v-switch> -->
                  <v-spacer></v-spacer>


                    <v-btn
                      v-if="!item.showResult && item.isLive && isInRole(0)"
                      outlined
                      :disabled="!item.isLive"
                      primary
                      @click="enterExam(item)"
                      color="primary"
                      :title="item.isLive? $t('exam.EnterToTheExam'): $t('exam.TheExamIsNotActivated')"
                    >
                      <v-icon >mdi-login-variant</v-icon>
                    </v-btn>

                    <v-btn
                      v-if="item.showResult && isInRole(0)"
                      outlined
                      :disabled="item.isLive"
                      primary
                      @click="showExamRes(item)"
                      color="info"
                      :title="$t('mail.ViewResults')"
                    >
                      <v-icon
                        >mdi-clipboard-text-search-outline</v-icon
                      >
                    </v-btn>
                </v-card-actions>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row class="mt-0">
      <v-col cols="12" class="pt-0 px-4">
        <v-item-group
          :multiple="isInRole(2)"
          v-model="rowKeysSelected"
          class="py-0"
          v-if="currentView == 'large' && examsFilter && examsFilter[0]"
        >
          <v-row class="ma-0">
            <v-col
              class="px-0 py-1"
              cols="12"
              xl="3"
              lg="3"
              md="6"
              sm="12"
              v-for="(item, index) in visiblePages"
              :key="index"
            >
              <v-item v-slot="{ active, toggle }" :value="item">
                <v-card
                  flat
                  class="pa-0 mx-1"
                  :color="
                    active ? ($vuetify.theme.dark ? '#505050' : '#e5e5e5') : ''
                  "
                  @click="toggle"
                >
                  <v-row class="ma-0 pa-0">
                    <v-col
                      v-if="isInRole(2)"
                      cols="7"
                      md="7"
                      sm="5"
                      class="ma-0 pa-0"
                    >
                      <div
                        dark
                        :class="
                          $vuetify.rtl
                            ? 'rounded-sm rounded-bl-xl pos pa-2 primary white--text'
                            : 'rounded-sm rounded-br-xl pos pa-2 primary white--text'
                        "
                      >
                        {{ item.datex | moment("YYYY/MM/DD - h:mm A ") }}
                      </div>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-menu v-if="isInRole(2)">
                      <template v-slot:activator="{ on: menu, attrs }">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on: tooltip }">
                            <v-btn
                              icon
                              color="grey"
                              class="mt-n3 mx-n3"
                              style="margin: 0px !important"
                              v-bind="attrs"
                              v-on="{ ...tooltip, ...menu }"
                            >
                              <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t("more") }}</span>
                        </v-tooltip>
                      </template>
                      <v-list>
                        <v-list-item @click="deletExam(item)">
                          <v-list-item-icon>
                            <v-icon>mdi-delete</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>{{
                            $t("delete")
                          }}</v-list-item-title>
                        </v-list-item>

                        <v-list-item @click="addDegree(item)">
                          <v-list-item-icon>
                            <v-icon>mdi-file-plus</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>
                            {{ $t("mail.AddAnExtraDegree") }}
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="sendNotiExam(item)">
                          <v-list-item-icon>
                            <v-icon>mdi-bell-ring</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>{{
                            $t("exam.sendNotiExam")
                          }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="reExam(item)">
                          <v-list-item-icon>
                            <v-icon>mdi-calendar-refresh</v-icon>
                          </v-list-item-icon>

                          <v-list-item-title>{{
                            $t("exam.reExam")
                          }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-row>
                  <v-card-title
                    dir="auto"
                    style="
                      word-break: break-word;
                      height: 85px;
                      overflow: hidden;
                    "
                    :title="item.title"
                  >
                    {{ item.title }}
                  </v-card-title>
                  <v-row class="text-center pt-0">
                    <v-col>
                      <v-chip outlined :color="!item.isLive ? 'error' : 'green'"
                        >{{ item.startDate | moment("YYYY/MM/DD - h:mm A") }}
                      </v-chip>
                    </v-col>
                    <v-col>
                      <v-chip outlined :color="!item.isLive ? 'error' : 'green'"
                        >{{ item.endDate | moment("YYYY/MM/DD - h:mm A") }}
                      </v-chip>
                    </v-col>
                  </v-row>
                  <v-row class="ma-0">
                    <v-col class="pa-0">
                      <v-list-item>
                        <v-list-item-content>
                          <v-card-actions class="" v-if="isInRole(2)">
                            <v-switch class="mt-0"
                              hide-details
                              inset
                              @change="isActiveFun(item)"
                              :label="
                                item.isActive
                                  ? $t('exam.Active')
                                  : $t('exam.NotActive')
                              "
                              v-model="item.isActive"
                            >
                            </v-switch>

                            <!-- <v-spacer></v-spacer> -->
                            <!-- <v-switch readonly
                              hide-details
                              class="mx-3"
                              @change="showRes(item)"
                              color="teal"
                              v-model="item.showResult"
                              :label="$t('exam.ShowResult')"
                              inset
                            ></v-switch> -->
                          </v-card-actions>
                          <v-card-actions>
                            <v-chip
                              outlined
                              v-if="item.examType == 0"
                              color="teal lighten-1"
                              dark
                            >
                              {{ $t("degrees.daily") }}
                            </v-chip>
                            <v-chip
                              outlined
                              v-else-if="item.examType == 3"
                              color="blue"
                              dark
                              >{{ $t("degrees.Monthly") }}
                            </v-chip>
                            <v-chip
                              outlined
                              v-else-if="item.examType == 2"
                              dark
                              color="orange  lighten-1"
                              >{{ $t("degrees.midFinal") }}
                            </v-chip>
                            <v-chip
                              outlined
                              v-else-if="item.examType == 1"
                              dark
                              color="red lighten-1"
                              >{{ $t("degrees.final") }}
                            </v-chip>
                            <v-chip v-if="item.showResult"  outlined class="mx-2"  color="teal"><v-icon color="teal">mdi-check</v-icon>{{ $t('exam.showResultsStatus') }}</v-chip>
                          </v-card-actions>
                          <v-list-item-title class="">
                            {{ item.subjectName }}
                          </v-list-item-title>

                          <v-list-item-subtitle>
                            {{ item.departmentName }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle>{{
                            `${$t("lecOne.section")} ${item.stageName} / ${
                              item.studyName
                            } / ${item.sectionName}`
                          }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-card-actions>
                    <v-btn
                      v-if="isInRole(2)"
                      outlined
                      primary
                      color="primary"
                      @click="showExam(item)"
                    >
                      {{ $t("edit") }}
                      <v-icon class="px-2">mdi-pencil</v-icon>
                    </v-btn>

                    <v-btn
                      v-if="!item.showResult"
                      outlined
                      :disabled="!item.isLive"
                      primary
                      @click="enterExam(item)"
                      color="primary"
                    >
                      {{
                        item.isLive
                          ? $t("exam.EnterToTheExam")
                          : $t("exam.TheExamIsNotActivated")
                      }}
                      <v-icon class="px-2">mdi-login-variant</v-icon>
                    </v-btn>

                    <v-btn
                      v-if="item.showResult && isInRole(0)"
                      outlined
                      :disabled="item.isLive"
                      primary
                      @click="showExamRes(item)"
                      color="primary"
                    >
                      {{ $t("mail.ViewResults") }}
                      <v-icon class="px-2"
                        >mdi-clipboard-text-search-outline</v-icon
                      >
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-item>
            </v-col>
            <v-col cols="12" md="12">
              <v-pagination
                v-if="exams[0]"
                v-model="page"
                :length="Math.ceil(examsFilter.length / perPage)"
                total-visible="15"
              ></v-pagination>
            </v-col>
          </v-row>
        </v-item-group>
      </v-col>
    </v-row>

    <v-row v-if="!examsFilter[0] && !loadingData">
      <v-col cols="12" md="12" class="text-center mt-10">
        <img src="../../assets/folder.svg" width="150px" />
        <h3>{{ $t("exam.ThereAreNoExams") }}</h3>
      </v-col>
    </v-row>

    <v-speed-dial
      v-if="isInRole(2)"
      v-model="fab"
      fixed
      bottom
      :left="$vuetify.rtl"
      :right="!$vuetify.rtl"
      :class="$store.state.drawerMessage ? 'ml-16' : ''"
      transition="slide-y-reverse-transition"
    >
      <template v-slot:activator>
        <v-btn v-model="fab" color="secondary" fab large elevation="6">
          <v-icon v-if="fab"> mdi-close </v-icon>
          <v-icon v-else> mdi-plus </v-icon>
        </v-btn>
      </template>
      <v-btn
        fab
        dark
        :title="$t('lecOne.AddFolder')"
        color="amber"
        @click="openDiaAddFolder"
      >
        <v-icon>mdi-folder</v-icon>
      </v-btn>
      <v-btn
        fab
        dark
        :title="$t('exam.AddExam')"
        color="indigo"
        @click="openDialog"
      >
        <v-icon>mdi-file</v-icon>
      </v-btn>
    </v-speed-dial>

    <v-dialog
      v-if="isInRole(2)"
      transition="dialog-bottom-transition"
      persistent
      max-width="600"
      v-model="dialogAddFolder"
    >
      <v-card>
        <v-toolbar color="primary darken-1" dark
          >{{ $t("lecOne.AddFolder") }}
        </v-toolbar>
        <v-card-text class="mt-4">
          <v-text-field
            :label="$t('lecOne.FolderName')"
            :placeholder="$t('lecOne.WriteTheFolderName')"
            outlined
            v-model="folderName"
            :rules="[required]"
          ></v-text-field>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-spacer></v-spacer>
          <v-btn color="error darken-1" text @click="closeDialogFolder">
            {{ $t("close") }}
          </v-btn>
          <v-btn
            :loading="sendLoad"
            color="primary darken-1"
            @click="AddFolder()"
            :disabled="!folderName"
          >
            {{ $t("save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="isInRole(2)"
      transition="dialog-bottom-transition"
      persistent
      max-width="600"
      v-model="dialogFolderEdit"
    >
      <v-card>
        <v-toolbar color="primary darken-1" dark>{{
          $t("lecOne.RenameFolder")
        }}</v-toolbar>
        <v-card-text class="mt-4">
          <v-text-field
            :label="$t('lecOne.FolderName')"
            :placeholder="$t('lecOne.WriteTheFolderName')"
            outlined
            v-model="folderName"
            :rules="[required]"
          ></v-text-field>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-spacer></v-spacer>
          <v-btn color="error darken-1" text @click="dialogFolderEdit = false">
            {{ $t("close") }}
          </v-btn>
          <v-btn
            :loading="sendLoad"
            color="primary darken-1"
            @click="editFolderSave()"
            :disabled="!folderName"
          >
            {{ $t("save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="moveFileDialog"
      max-width="600px"
      eager
      v-if="isInRole(2)"
    >
      <v-card>
        <v-toolbar v-if="rowKeysSelected" color="primary darken-1" dark
          >{{ moveFolder ? $t("lecOne.MoveFolder") : $t("exam.MoveExams") }} ({{
            moveFolder ? moveFolderInfo.folderName : rowKeysSelected.length
          }})
        </v-toolbar>
        <div style="max-height: 430px; overflow: auto">
          <v-treeview
            ref="treeReference"
            :items="foldersTree"
            activatable
            item-text="folderName"
            item-key="guid"
            item-children="children"
            selection-type="independent"
            @update:active="function (val) {
                selectedFolderTree = val[0];
              }
            "
          >
            <template v-slot:prepend="{ item }">
              <v-icon
                color="primary"
                v-if="item.guid == '00000000-0000-0000-0000-000000000000'"
              >
                mdi-home
              </v-icon>
              <v-icon v-else> mdi-folder </v-icon>
            </template>
            <template v-slot:label="{ item }">
              <v-row>
                <v-col align-self="center">
                  {{ item.folderName }}
                </v-col>
              </v-row>
            </template>
          </v-treeview>
        </div>
        <v-card-actions class="justify-end">
          <v-spacer></v-spacer>
          <v-btn color="error darken-1" text @click="moveFileDialog = false">
            {{ $t("close") }}
          </v-btn>
          <v-btn
            :loading="sendLoad"
            color="primary darken-1"
            @click="moveFolder == true ? moveFolderFunc() : moveFiles()"
            :disabled="!selectedFolderTree"
          >
            {{ $t("Move") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <AddExam
      v-if="isInRole(2)"
      ref="addExamComponent"
      v-on:send-result-values="addFun"
      :folderId="folderId"
    ></AddExam>
    <ShowStudentRes v-if="isInRole(0)" ref="childComponent"></ShowStudentRes>

  </div>
</template>

<script>
import Axios from "axios";
import AddExam from "../../components/exams/addExam.vue";
import ShowStudentRes from "../../components/exams/showStudentRes.vue";

import { bus } from "../../main";

export default {
  components: {
    AddExam,
    ShowStudentRes,
  },
  props: ["folderId"],
  data() {
    return {
      showFolders: true,
      currentView: "",
      isNotALive: [],
      isALive: [],
      tab: 0,
      loadingData: false,
      dateNow: Date.now(),
      dataGridRefName: "dataGrid",
      pageSizes: [5, 10, 20],
      rtlEnabled: true,
      exams: [],
      search: "",
      isLiveOnly: false,
      folders: [],
      folderGuid: null,
      dialogAddFolder: false,
      currentFolder: null,
      folderGuidEdit: null,
      moveFileDialog: false,
      foldersTree: [],
      foldersTreeList: [],
      selectedFolderTree: null,
      moveFolder: false,
      moveFolderInfo: null,
      rowKeysSelected: [],
      folderName: "",
      dialogFolderEdit: false,
      sendLoad: false,
      fab: false,
      active: [],
      required: (value) => !!value || this.$t("ThisFieldIsRequired"),
      page: 1,
      perPage: 8,
      headersExam: [
        {
          text: this.$t("exam.examTitle"),
          value: "title",
          show: true,
          cellClass: 'pa-0'
        },
        {
          text: this.$t("createdDate"),
          value: "datex",
          show: this.isInRole(2),
          cellClass: 'pa-0'
        },
        {
          text: this.$t("exam.examType"),
          value: "examType",
          show: true,
          cellClass: 'pa-0'
        },
        {
          text: this.$t("exam.startDate"),
          value: "startDate",
          show: true,
          cellClass: 'pa-0'
        },
        {
          text: "",
          value: "actions",
          sortable: false,
          show: true,
          cellClass: 'pa-0'
        },
      ],
    };
  },
  computed: {
    visiblePages() {
      return this.examsFilter.slice(
        (this.page - 1) * this.perPage,
        this.page * this.perPage
      );
    },
    examsFilter() {
      let data = this.exams;

      if (this.isLiveOnly) {
        data = data.filter((item) => item.isLive === true);
      }

      if (this.search) {
        return data.filter((item) => this.search
            .toLowerCase()
            .split(" ")
            .every(
              (v) => item.subjectName.toLowerCase().includes(v) ||
                item.title.toLowerCase().includes(v) ||
                item.studyName.toLowerCase().includes(v) ||
                item.stageName.toLowerCase().includes(v) ||
                item.departmentName.toLowerCase().includes(v) ||
                item.sectionName.toLowerCase().includes(v)

            ));
      } else {
        return data;
      }
    },
    foldersFilter() {
      let dataList = [];
      dataList = this.folders.filter((item) => this.search
          .toLowerCase()
          .split(" ")
          .every(
            (v) => item.folderName.toLowerCase().includes(v) ||
              item.createdDate.toLowerCase().includes(v)
          ));

      return dataList;
    },
    noNodesSelected() {
      return !this.rowKeysSelected || this.rowKeysSelected.length === 0;
    },
    someNodesSelected() {
      return (
        this.rowKeysSelected &&
        this.rowKeysSelected.length > 0 &&
        !this.allNodesSelected
      );
    },
    allNodesSelected() {
      return (
        this.rowKeysSelected &&
        this.rowKeysSelected.length > 0 &&
        this.rowKeysSelected.length === this.examsFilter.length
      );
    },
  },
  methods: {
    toggleCompleteSelection() {
      if (this.noNodesSelected || this.someNodesSelected) {
        this.rowKeysSelected = this.examsFilter;
      } else {
        this.rowKeysSelected = [];
      }
    },
    openDiaAddFolder() {
      this.rowKeysSelected = [];
      this.dialogAddFolder = !this.dialogAddFolder;
    },
    deleteFolder(item) {
      if (!this.isInRole(2)) return;

      this.$swal
        .fire({
          title: this.$t("AreYouSureAboutTheOperation"),
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: this.$t("yes"),
          cancelButtonText: this.$t("no"),
        })
        .then((result) => {
          if (result.value) {
            this.axios
              .delete(
                `Folders/DeleteFolder?FolderGuid=${item.guid}&FolderType=exam`
              )
              .then((res) => {
                if (res.data.statusCode == 1) {
                  this.getExams();
                  this.$swal.fire({
                    title: this.$t("operationAccomplishedSuccessfully"),
                    text: "",
                    icon: "success",
                    confirmButtonText: this.$t("close"),
                    toast: true,
                    position: "bottom-start",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                      toast.addEventListener(
                        "mouseenter",
                        this.$swal.stopTimer
                      );
                      toast.addEventListener(
                        "mouseleave",
                        this.$swal.resumeTimer
                      );
                    },
                  });
                } else if (res.data.statusCode == 2) {
                  this.$swal.fire({
                    title: this.$t("errors.CannotDeleteFolderContainsExams"),
                    text: "",
                    icon: "error",
                    confirmButtonText: this.$t("close"),
                  });
                } else if (res.data.statusCode == 3) {
                  this.$swal.fire({
                    title: this.$t(
                      "errors.CannotDeleteFolderContainsChildFolders"
                    ),
                    text: "",
                    icon: "error",
                    confirmButtonText: this.$t("close"),
                  });
                } else {
                  this.$swal.fire({
                    title: this.$t(
                      "errors.AnErrorOccurredTheOperationDidNotCompleteSuccessfully"
                    ),
                    text: "",
                    icon: "error",
                    confirmButtonText: this.$t("close"),
                  });
                }
              })
              .catch(() => {
                this.$swal.fire({
                  title: this.$t("AnErrorOccurredDuringTheProcess"),
                  text: "",
                  icon: "error",
                  confirmButtonText: this.$t("close"),
                });
              });
          }
        });
    },
    editFolder(item) {
      this.folderName = item.folderName;
      this.folderGuidEdit = item.guid;
      this.folderEdit = item;
      this.dialogFolderEdit = true;
    },
    moveFolderPopUp(item) {
      this.folderGuidEdit = item.guid;
      this.moveFolderInfo = item;
      this.moveFolder = true;
      this.getFoldersTree("folders");
      this.moveFileDialog = true;
    },
    editFolderSave() {
      if (!this.isInRole(2)) return;

      const data = {
        Guid: this.folderGuidEdit,
        FolderName: this.folderName,
        FolderType: "exam",
      };
      this.axios
        .put("Folders/RenameFolder", data)
        .then((res) => {
          this.getExams();
          this.$swal.fire({
            title: this.$t("operationAccomplishedSuccessfully"),
            text: "",
            icon: "success",
            confirmButtonText: this.$t("close"),
            toast: true,
            position: "bottom-start",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
          this.dialogFolderEdit = false;
        })
        .catch(() => {
          this.$swal.fire({
            title: this.$t("AnErrorOccurredDuringTheProcess"),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close"),
          });
        });
    },
    closeDialogFolder() {
      this.dialogAddFolder = false;
      this.getExams();
    },
    OpenFolder(guid) {
      this.$router.push({
        path: `/exams/${guid}`,
        params: {
          folderId: guid,
        },
      });
    },
    AddFolder() {
      if (!this.isInRole(2)) return;
      this.sendLoad = true;

      if (
        this.folderId == "undefined" ||
        this.folderId == null ||
        !this.folderId
      ) {
        this.folderGuid = "00000000-0000-0000-0000-000000000000";
      } else {
        this.folderGuid = this.folderId;
      }

      const data = {
        FolderName: this.folderName,
        ParentGuid: this.folderGuid,
        FilesGuid: [],
        FolderType: "exam",
      };
      // console.log(data);
      this.axios
        .post("Folders/AddFolder", data)
        .then((res) => {
          this.getExams();
          this.fileURL = null;
          this.$swal.fire({
            title: this.$t("operationAccomplishedSuccessfully"),
            text: "",
            icon: "success",
            confirmButtonText: this.$t("close"),
            toast: true,
            position: "bottom-start",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
          this.dialogAddFolder = false;

          // console.log(res);
        })
        .catch(() => {
          this.$swal.fire({
            title: this.$t("AnErrorOccurredDuringTheProcess"),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close"),
          });
        })
        .finally(() => {
          this.sendLoad = false;
        });
    },
    moveFiles() {
      this.sendLoad = true;
      const data = {
        FolderGuid: this.selectedFolderTree,
        FilesGuid: this.rowKeysSelected.map((m) => m.guid),
        FolderType: "exam",
      };

      this.axios
        .post("Folders/MoveFiles", data)
        .then((res) => {
          this.getExams();
          this.fileURL = null;
          this.$swal.fire({
            title: this.$t("operationAccomplishedSuccessfully"),
            text: "",
            icon: "success",
            confirmButtonText: this.$t("close"),
            toast: true,
            position: "bottom-start",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
          this.rowKeysSelected = [];
          if (this.currentView == "large") {
            this.$forceUpdate();
          }
          this.selectedFolderTree = null;
          this.moveFileDialog = false;

          // console.log(res);
        })
        .catch(() => {
          this.$swal.fire({
            title: this.$t("AnErrorOccurredDuringTheProcess"),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close"),
          });
        })
        .finally(() => {
          this.sendLoad = false;
        });
    },
    moveFolderFunc() {
      this.sendLoad = true;
      const data = {
        MoveToFolderGuid: this.selectedFolderTree,
        FolderGuid: this.folderGuidEdit,
        FolderType: "exam",
      };

      this.axios
        .post("Folders/MoveFolder", data)
        .then((res) => {
          if (res.data.statusCode == 1) {
            this.getExams();
            this.$swal.fire({
              title: this.$t("operationAccomplishedSuccessfully"),
              text: "",
              icon: "success",
              confirmButtonText: this.$t("close"),
              toast: true,
              position: "bottom-start",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              },
            });

            this.selectedFolderTree = null;
            this.folderGuidEdit = null;
            this.moveFileDialog = false;
          } else if (res.data.statusCode == 2) {
            this.$swal.fire({
              title: this.$t("errors.FolderCannotParentItself"),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close"),
            });
          } else if (res.data.statusCode == 3) {
            this.$swal.fire({
              title: this.$t("errors.FolderCannotChildHisChildren"),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close"),
            });
          } else {
            this.$swal.fire({
              title: this.$t(
                "errors.AnErrorOccurredTheOperationDidNotCompleteSuccessfully"
              ),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close"),
            });
          }
        })
        .catch(() => {
          this.$swal.fire({
            title: this.$t("AnErrorOccurredDuringTheProcess"),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close"),
          });
        })
        .finally(() => {
          this.sendLoad = false;
        });
    },
    getFoldersTree(moveType) {
      this.foldersTreeList = [];
      this.foldersTree = [];
      this.overlay = true;
      this.axios
        .get(
          `Folders/GetFoldersTree?FolderType=exam&MoveType=${moveType}&FolderGuid=${this.folderGuidEdit}`
        )
        .then((response) => {
          const general = {
            guid: "00000000-0000-0000-0000-000000000000",
            folderName: this.$t("lecOne.Home"),
          };

          this.foldersTreeList.push(general);
          this.foldersTree.push(general);

          for (let i = 0; i < response.data.data.length; i++) {
            var tree = response.data.data[i];
            const children = response.data.data.filter((f) => f.parentGuid == tree.guid);
            if (children.length > 0) {
              children.forEach((child) => {
                const childNode = { ...tree, item: child, vnode: null };
                this.$refs.treeReference.nodes[child.guid] = childNode;
              });
              tree.children = children;
            }
            this.foldersTreeList.push(tree);
            if (
              response.data.data[i].parentGuid ==
              "00000000-0000-0000-0000-000000000000"
            ) {
              this.foldersTree.push(tree);
            }
          }
          if (moveType == "files") {
            this.moveFolder = false;
          } else {
            this.moveFolder = true;
          }

          this.moveFileDialog = true;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.overlay = false;
        });
    },
    reExam(data) {
      this.rowKeysSelected = [];
      this.$refs.addExamComponent.reExam(data.guid, data.subjectGuid);
    },
    sendNotiExam(data) {
      // console.log(data)
      Axios.post(`Exams/SendNotification?examGuid=${data.guid}`)
        .then((res) => {
          this.$swal.fire({
            title: this.$t("operationAccomplishedSuccessfully"),
            text: "",
            icon: "success",
            confirmButtonText: this.$t("close"),
            toast: true,
            position: "bottom-start",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
        })
        .catch(() => {
          this.$swal.fire({
            title: this.$t(
              "errors.AnErrorOccurredTheOperationDidNotCompleteSuccessfully"
            ),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close"),
          });
        });
    },
    addFun(val) {
      // console.log(val);
      this.$router.push({
        path: `/showExam=${val}`,
        params: { id: val },
      });
    },
    showExam(item) {
      this.$router.push({
        path: `/showExam=${item.guid}`,
        params: { id: item.guid },
      });
    },
    enterExam(item) {
      // console.log(item);
      this.$router.push({
        path: `/enterExam=${item.guid}`,
        params: { id: item.guid },
      });
    },
    showExamRes(item) {
      this.$refs.childComponent.setValue(item.guid);
    },
    isActiveFun(item) {
      const sendData = {
        Guid: item.guid,
        title: item.title,
        description: item.description,
        startDate: item.startDate,
        endDate: item.endDate,
        isActive: item.isActive,
        showResult: item.showResult,
        examType: item.examType,
        enablePasswordToEnter: item.enablePasswordToEnter,
          passwordToEnter: item.passwordToEnter,
          numberOfQuestionsInPage: item.numberOfQuestionsInPage,
          showCorrectAnswerAfterSubmit: item.showCorrectAnswerAfterSubmit,
          restrictedMovingToNext: item.restrictedMovingToNext,
          restrictedByTime: item.restrictedByTime,
          restrictedTime: item.restrictedTime,
      };
      // console.log(sendData);
      this.axios
        .put("Exams/Edit", sendData)
        .then((res) => {
          this.getExams();
        })
        .catch(() => {})
        .finally(() => {});
    },
    showRes(item) {
      // console.log(item.data);
      if (item.isActive == true) {
        this.getExams();
        this.$swal.fire({
          title: this.$t("errors.YouCanNotShowResultOfTheExamTheExamIsActive"),
          text: "",
          icon: "error",
          confirmButtonText: this.$t("close"),
        });
      } else {
        const sendData = {
          Guid: item.guid,
          title: item.title,
          description: item.description,
          startDate: item.startDate,
          endDate: item.endDate,
          isActive: item.isActive,
          showResult: item.showResult,
          examType: item.examType,
          enablePasswordToEnter: item.enablePasswordToEnter,
          passwordToEnter: item.passwordToEnter,
          numberOfQuestionsInPage: item.numberOfQuestionsInPage,
          showCorrectAnswerAfterSubmit: item.showCorrectAnswerAfterSubmit,
          restrictedMovingToNext: item.restrictedMovingToNext,
          restrictedByTime: item.restrictedByTime,
          restrictedTime: item.restrictedTime,
        };
        // console.log(sendData);
        this.axios
          .put("Exams/Edit", sendData)
          .then((res) => {
            this.getExams();
          })
          .catch(() => {})
          .finally(() => {});
      }
    },

    openDialog() {
      this.rowKeysSelected = [];
      this.$refs.addExamComponent.setValue();
    },
    // openEditDialog(item) {
    //   this.$refs.editExamComponent.setValue(item, true);
    // },
    openAddStudentDialog(item) {
      this.$refs.editExamComponent.setValue(item, false);
    },
    deletExam(item) {
      // console.log(item);
      this.$swal
        .fire({
          title: this.$t("AreYouSureAboutTheOperation"),
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: this.$t("yes"),
          cancelButtonText: this.$t("no"),
        })
        .then((result) => {
          if (result.value) {
            this.axios
              .delete(`Exams/Delete?Guid=${item.guid}`)
              .then((res) => {
                if (res.data.statusCode == 1) {
                  this.getExams();
                  this.$swal.fire({
                    title: this.$t("operationAccomplishedSuccessfully"),
                    text: "",
                    icon: "success",
                    confirmButtonText: this.$t("close"),
                    toast: true,
                    position: "bottom-start",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                      toast.addEventListener(
                        "mouseenter",
                        this.$swal.stopTimer
                      );
                      toast.addEventListener(
                        "mouseleave",
                        this.$swal.resumeTimer
                      );
                    },
                  });
                } else if (res.data.statusCode == 2) {
                  this.$swal.fire({
                    title: this.$t(
                      "errors.YouCanNotDeleteTheExamTheExamIsActiv"
                    ),
                    text: "",
                    icon: "error",
                    confirmButtonText: this.$t("close"),
                  });
                } else if (res.data.statusCode == 3) {
                  this.$swal.fire({
                    title: this.$t("errors.ExamNotFound"),
                    text: "",
                    icon: "error",
                    confirmButtonText: this.$t("close"),
                  });
                } else if (res.data.statusCode == 4) {
                  this.$swal.fire({
                    title: this.$t(
                      "errors.YouCanNotDeleteTheExamItHasBeenAnsweredByTheStudents"
                    ),
                    text: "",
                    icon: "error",
                    confirmButtonText: this.$t("close"),
                  });
                } else {
                  this.$swal.fire({
                    title: this.$t(
                      "errors.AnErrorOccurredTheOperationDidNotCompleteSuccessfully"
                    ),
                    text: "",
                    icon: "error",
                    confirmButtonText: this.$t("close"),
                  });
                }
              })
              .catch(() => {
                this.$swal.fire({
                  title: this.$t("AnErrorOccurredDuringTheProcess"),
                  text: "",
                  icon: "error",
                  confirmButtonText: this.$t("close"),
                });
              })
              .finally(() => {});
          }
        });
    },
    addDegree(item) {
      // console.log(item);
      this.$router.push({
        path: `/extraDegree/${item.guid}`,
        params: { id: item.guid },
      });
    },
    getExams() {
      this.isNotALive = [];
      this.isALive = [];
      this.loadingData = true;

      if (
        this.folderId == "undefined" ||
        this.folderId == null ||
        !this.folderId
      ) {
        this.folderGuid = "00000000-0000-0000-0000-000000000000";
      } else {
        this.folderGuid = this.folderId;
      }

      this.axios
        .get(`Exams/Get?FolderGuid=${this.folderGuid}&GroupByFolder=true`)
        .then((res) => {
          if (this.isInRole(2)) {
            this.exams = res.data.data.exams;
            this.folders = res.data.data.folders;
            this.currentFolder = res.data.data.currentFolder;
            this.rowKeysSelected = [];
          } else {
            this.exams = res.data.data;
          }

          // this.exams.forEach(e => {
          //   if (e.isLive) {
          //     this.isALive.push(e);
          //   } else {
          //     this.isNotALive.push(e);
          //   }
          // });
        })
        .catch(() => {})
        .finally(() => {
          this.loadingData = false;
        });
    },
    getHeaders() {
      return this.headersExam.filter((m) => m.show);
    },
    back() {
      this.$router.go(-1);
    },
    setView(view) {
      this.rowKeysSelected = [];

      this.currentView = view;
      localStorage.setItem("examsView", view);
    },
    onResize() {
      if (window.innerWidth <= 600) {
        this.currentView = "large";
      } else {
        this.currentView =
          localStorage.getItem("examsView") != "large" &&
          localStorage.getItem("examsView") != "details"
            ? "large"
            : localStorage.getItem("examsView");
      }
    },
  },
  created() {
    this.currentView =
      localStorage.getItem("examsView") != "large" &&
      localStorage.getItem("examsView") != "details"
        ? "large"
        : localStorage.getItem("examsView");

    this.getExams();
    bus.$on("showExamResultDialog", (examGuid) => {
      if (this.$refs.childComponent) {
        this.$refs.childComponent.setValue(examGuid);
      }
    });
  },
};
</script>

<style lang="css" scope>
.dx-datagrid .dx-row > td {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.v-label[for="isLiveOnlyKey"] {
  position: initial !important;
}
.select-all-cards .v-input__control {
  height: 25px;
}
.posLeft {
  position: absolute;
  left: 10px;
}
.posRight {
  position: absolute;
  right: 10px;
}
</style>
